import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useAuth } from "../../../../context/auth";
import { Uploader } from "../../../../utils/uploadFn";
const CpatureContext = createContext(null);

const initialState = {
  email: "",
  documentDetails: {},
  documentData: [],
  loading: "",
  demoDocumentDetails: {},
  demoDocumentData: [],
  addStep: false,
  step_number: null,
  previousStepNumber: null,
  nextStepNumber: null,
  voiceOverClicked: false,
  voiceScript: [],
  voiceScriptLoading: false,
  displayData: [],
  generateStatus: false,
  imageEdit: {
    open: false,
    selectedStep: {},
  },
  editToolSelected: "",
  blurItems: [],
  blurPercentage: 0,
  blurEdit: {
    open: false,
    selectedBlur: {},
  },
  blurEditClicked: false,
  shapeItems: [],
  shapeEdit: {
    open: false,
    selectedShape: {},
  },
  shapeEditClicked: false,
  positionDetails: {
    X: "",
    Y: "",
    width: 46,
    height: 46,
  },
  cropDetails: {},
  imageData: {
    height: "",
    width: "",
  },
  croppedImageUrl: "",
  oldDocumentData: [],
  oldVoiceScript: [],
  reply: "",
  subReply: "",
  replyEmail: "",
  comments: [],
  commentMenu: {
    commentId: "",
    open: false,
  },
  editComment: {
    commentId: "",
    open: false,
  },
  subCommentMenu: {
    commentId: "",
    open: false,
  },
  editSubComment: {
    commentId: "",
    parentId: "",
    open: false,
  },
  embedClicked: false,
  selectedEmbed: "",
  captureEmbedValues: {
    viewType: "scrollView",
    viewName: "responsive",
    creatorName: true,
    colorValue: "#B3B3B3",
    customizeDimensions: {
      width: 1280,
      height: 720,
    },
  },
  videoEmbedValues: {
    creatorName: true,
    colorValue: "#B3B3B3",
    borderRadius: 12,
    videoDimensions: {
      width: 1280,
      height: 720,
    },
  },
  analyticsClicked: false,
  videoCtaClicked: false,
  selectedForm: "sales",
  salesForm: [],
  analyticsData: {
    totalViews: 1126,
    uniqueViews: 0,
    eventsByDate: [
      {
        date: "20240613",
        eventCount: 256,
      },
      {
        date: "20240614",
        eventCount: 278,
      },
      {
        date: "20240615",
        eventCount: 226,
      },
      {
        date: "20240616",
        eventCount: 175,
      },
      {
        date: "20240617",
        eventCount: 191,
      },
    ],
    daysAgoReport: [
      {
        user: "",
        daysAgo: 8,
        eventCount: 1123,
      },
      {
        user: "1.0261382005176815e+20",
        daysAgo: 10,
        eventCount: 2,
      },
      {
        user: "1.05141382451119e+20",
        daysAgo: 12,
        eventCount: 1,
      },
    ],
    viewsByCityPercentage: [
      { city: "Santiago, Chile", count: "49.1%" },
      { city: "(not set), Chile", count: "13.8%" },
      { city: "Concepcion, Chile", count: "8.4%" },
      { city: "Los Angeles, Chile", count: "1.8%" },
      { city: "Temuco, Chile", count: "2.6%" },
      { city: "Copiapo, Chile", count: "1.5%" },
      { city: "Villa Alemana, Chile", count: "1.3%" },
      { city: "San Pedro de la Paz, Chile", count: "1.8%" },
      { city: "La Serena, Chile", count: "1.2%" },
      { city: "Puerto Montt, Chile", count: "1.1%" },
      { city: "San Antonio, Chile", count: "1.5%" },
      { city: "Antofagasta, Chile", count: "1.6%" },
      { city: "Curico, Chile", count: "0.7%" },
      { city: "Vina del Mar, Chile", count: "1.6%" },
      { city: "08666, Brazil", count: "0.7%" },
      { city: "Villarrica, Chile", count: "0.7%" },
      { city: "Maipu, Chile", count: "0.8%" },
      { city: "Rancagua, Chile", count: "0.7%" },
      { city: "Punta Arenas, Chile", count: "0.8%" },
      { city: "Lota, Chile", count: "0.4%" },
      { city: "Osorno, Chile", count: "0.4%" },
      { city: "Talca, Chile", count: "0.4%" },
      { city: "Calama, Chile", count: "0.4%" },
      { city: "Los Andes, Chile", count: "0.6%" },
      { city: "Arica, Chile", count: "0.5%" },
      { city: "Ancud, Chile", count: "0.4%" },
      { city: "Chillan, Chile", count: "0.3%" },
      { city: "Coquimbo, Chile", count: "0.4%" },
      { city: "Ovalle, Chile", count: "0.2%" },
      { city: "Quillota, Chile", count: "0.3%" },
      { city: "San Fernando, Chile", count: "0.4%" },
      { city: "6240, Australia", count: "0.3%" },
      { city: "Linares, Chile", count: "0.4%" },
      { city: "Quilpue, Chile", count: "0.4%" },
      { city: "Valdivia, Chile", count: "0.4%" },
      { city: "Victoria, Chile", count: "0.3%" },
      { city: "Puerto Varas, Chile", count: "0.2%" },
      { city: "Oberspreewald-Lausitz, Germany", count: "0.4%" },
      { city: "Laval, Canada", count: "0.1%" },
      { city: "San Felipe, Chile", count: "0.1%" },
      { city: "(not set), Ecuador", count: "0.1%" },
      { city: "67814, Germany", count: "0.2%" },
      { city: "Bojongmangu, Indonesia", count: "0.2%" },
      { city: "Coronel, Chile", count: "0.1%" },
      { city: "Coyhaique, Chile", count: "0.1%" },
      { city: "Valparaiso, Chile", count: "0.2%" },
      { city: "Talcahuano, Chile", count: "0.1%" },
      { city: "Don Torcuato, Argentina", count: "0.1%" },
      { city: "Iquique, Chile", count: "0.2%" },
    ],
  },
  filterMenu: {
    open: false,
    value: "Last 30 days",
  },
  salesFormSettings: {
    showQuestionMenu: false,
    questionIndex: "",
  },
  sharePopup: {
    open: false,
  },
  shareType: "",
  sharedEmails: [],
  invite: {
    open: false,
  },
  ownerChange: {
    open: false,
  },
  commentEnable: false,
  htmlDemoActiveStep: 0,
  issueBox: {
    open: false,
  },
  showTitlePopup: false,
  demoDeletePopup: false,
  demoStepMenu: {
    open: false,
    id: "",
    previousStepNumber: "",
    nextStepNumber: ""
  },
  featureSeleceted: "",
  allignmentMenu: {
    open: false,
  },
  fontMenu: {
    open: false,
  },
  activeStepId: '',
  activeStepCord: {},
  activeStepIndex: 'start',
  demoEditcallOut: {
    backgroundColor: "#E23B66",
    textColor: "#FFFFFF",
    headingColor: "#404B59",
    heading: '',
    calloutStyle: 'Tooltip',
    text: '',
    textBold: false,
    textItalic: false,
    textUnderline: false,
    arrowAlignment: 'Left',
    font: 'Inter',
    borderColor: 'No Fill',
    buttonColor: "#7F4CF8"
  },
  demoEditConfig: {
    prevArrow: false,
    nextArrow: false,
    stepNo: false,
    textBtn: false,
    iconBtn: false,
  },
  getDemoDocs: null,
  getCaptureDocs: null,
  themeOpened: false,
  colorOpenMenu: {
    callout: false,
    border: false,
    text: false,
    button: false,
  },
  activeMenuPosition: {},
  activeMenuHorPositon: "",
  lastStepText: '',
  lastStepHeading: "",
  captureEditCallout: {
    backgroundColor: "#E23B66",
    textColor: "#FFFFFF",
    headingColor: "#404B59",
    heading: '',
    calloutStyle: 'Tooltip',
    text: '',
    textBold: false,
    textItalic: false,
    textUnderline: false,
    arrowAlignment: 'Left',
    font: 'Inter',
    borderColor: 'No Fill',
    buttonColor: "#7F4CF8"
  },
  captureEditConfig: {
    prevArrow: false,
    nextArrow: false,
    stepNo: false,
    textBtn: false,
    iconBtn: false,
  },
  captureActiveStepId: "",
  captureActiveStepIndex: "start",
  captureActiveStepCord: {},
  captureVideoUrls: {
    introVideo: "",
    outroVideo: "",
  },
  demoVideoUrls: {
    introVideo: "",
    outroVideo: ""
  },
  captureBrandKitDetails: {},
  demoBrandKitDetails: {},
  introOutroMenu: {
    open: false,
    id: "",
  },
  introOutroPopup: {
    open: false,
    id: ""
  },
  selectedIntroOutro:{
    introVideo:"brandKit",
    outroVideo:"brandKit"
  }

};

const CpatureReducer = (state, action) => {
  switch (action.type) {
    case "FETCHING_CAPTURE_STEPS":
      return {
        ...state,
        email: action?.data?.email,
        documentDetails: action?.data?.details,
        documentData: action?.data?.totalSteps,
        voiceScript: action?.data?.voiceoverScript,
        shareType: action?.data?.shareType,
        getCaptureDocs: action?.data?.getCaptureDocs,
        captureBrandKitDetails: action?.data?.brandKitDetails,
      };
    case "LOADING_CHANGE":
      return {
        ...state,
        loading: action?.data,
      };
    case "ADD_STEP_POPUP_OPEN":
      return {
        ...state,
        addStep: true,
        step_number: action?.data?.step_number,
        previousStepNumber: action?.data?.previousStepNumber,
        nextStepNumber: action?.data?.nextStepNumber,
      };
    case "HANDLE_VOICE_SCRIPT":
      return {
        ...state,
        voiceScript: action?.data,
      };
    case "ADD_STEP_POPUP_CLOSE":
      return {
        ...state,
        addStep: false,
        step_number: null,
        previousStepNumber: null,
        nextStepNumber: null,
      };
    case "HANDLE_VOICE_LOADING":
      return {
        ...state,
        voiceScriptLoading: false,
      };
    case "SET_DISPLAY_DATA":
      return {
        ...state,
        displayData: action.data,
      };
    case "GENERATE_CLICKED":
      return {
        ...state,
        voiceOverClicked: action.voiceOverClicked,
      };
    case "GENERATE_STATUS":
      return {
        ...state,
        generateStatus: action.generateStatus,
      };
    case "DELETE_STEP":
      return {
        ...state,
        documentData: action.data,
      };
    case "HANDLE_DOCUMENTDATA":
      return {
        ...state,
        documentData: action.data,
      };
    case "OPEN_EDIT_IMAGE":
      return {
        ...state,
        imageEdit: {
          ...state.imageEdit,
          open: true,
          selectedStep: action?.data,
        },
      };
    case "CLOSE_EDIT_IMAGE":
      return {
        ...state,
        imageEdit: {
          ...state.imageEdit,
          open: false,
          selectedStep: {},
        },
        positionDetails: {},
        shapeItems: [],
        blurItems: [],
        blurEdit: {
          ...state.blurEdit,
          open: false,
          selectedBlur: {},
        },
        shapeEdit: {
          ...state.shapeEdit,
          open: false,
          selectedShape: {},
        },
        croppedImageUrl: "",
        cropDetails: {},
        editToolSelected: "",
        blurEditClicked: false,
        shapeEditClicked: false,
        blurPercentage: 0,
      };
    case "EDIT_TOOL_SELECTED":
      return {
        ...state,
        editToolSelected: action?.data,
      };
    case "GO_TO_EDIT_TOOL_HOME":
      return {
        ...state,
        editToolSelected: "",
        blurEdit: {
          open: false,
          selectedBlur: {},
        },
        shapeEdit: {
          open: false,
          selectedShape: {},
        },
        blurEditClicked: false,
        shapeEditClicked: false,
      };
    case "HANDLE_BLUR_PERCENTAGE":
      return {
        ...state,
        blurPercentage: action?.data,
      };
    case "OPEN_BLUR_EDIT":
      return {
        ...state,
        blurEdit: {
          open: true,
          selectedBlur: action?.data,
        },
        blurEditClicked: true,
      };
    case "CLOSE_BLUR_EDIT":
      return {
        ...state,
        blurEdit: {
          open: false,
          selectedBlur: {},
        },
        blurEditClicked: false,
      };
    case "OPEN_SHAPE_EDIT":
      return {
        ...state,
        shapeEdit: {
          open: true,
          selectedShape: action?.data,
        },
        shapeEditClicked: true,
      };
    case "CLOSE_SHAPE_EDIT":
      return {
        ...state,
        shapeEdit: {
          open: false,
          selectedShape: {},
        },
        shapeEditClicked: false,
      };
    case "HANDLE_BLUR_TOOL":
      return {
        ...state,
        blurItems: action?.data,
      };
    case "HANDLE_SHAPE_TOOL":
      return {
        ...state,
        shapeItems: action?.data,
      };
    case "CHANGING_THE_POSITION":
      return {
        ...state,
        positionDetails: {
          X: action?.X,
          Y: action?.Y,
          width: action?.width,
          height: action?.height,
        },
      };
    case "CHANGING_THE_CROPDETAILS":
      return {
        ...state,
        cropDetails: {
          x: action?.x,
          y: action?.y,
          width: action?.width,
          height: action?.height,
          unit: action?.unit,
          // imageUrl:action?.imageUrl
        },
      };
    case "SAVE_THE_CROPPED_IMAGE":
      return {
        ...state,
        croppedImageUrl: action?.imageUrl,
      };
    case "SAVE_THE_IMAGE_DIMENSIONS":
      return {
        ...state,
        imageData: {
          width: action?.width,
          height: action?.height,
        },
      };
    case "UNDO":
      return {
        ...action?.data,
      };
    case "REDO":
      return {
        ...action?.data,
      };
    case "VOICESCRIPT_LOADING_TRUE":
      return {
        ...state,
        voiceScriptLoading: true,
      };
    case "ADD_OLD_DOCUMENT_DATA":
      return {
        ...state,
        oldDocumentData: action?.data,
      };
    case "ADD_OLD_VOICESCRIPT":
      return {
        ...state,
        oldVoiceScript: action?.data,
      };
    case "HANDLE_REPLY":
      return {
        ...state,
        reply: action?.data,
        subReply: "",
        replyEmail: "",
        editSubComment: {
          commentId: "",
          parentId: "",
          open: false,
        },
      };
    case "COMMENTS_FETCH":
      return {
        ...state,
        comments: action?.data?.Comments,
        commentEnable: action?.data?.commentEnable,
      };
    case "HANDLE_COMMENTS_ENABLE":
      return {
        ...state,
        commentEnable: true,
      };
    case "OPEN_COMMENTS_MENU":
      return {
        ...state,
        commentMenu: {
          commentId: action?.data,
          open: true,
        },
      };
    case "CLOSE_COMMENTS_MENU":
      return {
        ...state,
        commentMenu: {
          commentId: "",
          open: false,
        },
      };
    case "OPEN_SUB_COMMENTS_MENU":
      return {
        ...state,
        subCommentMenu: {
          commentId: action?.data,
          open: true,
        },
      };
    case "CLOSE_SUB_COMMENTS_MENU":
      return {
        ...state,
        subCommentMenu: {
          commentId: "",
          open: false,
        },
      };
    case "EDIT_COMMENT":
      return {
        ...state,
        editComment: {
          commentId: action?.data,
          open: true,
        },
        commentMenu: {
          commentId: "",
          open: false,
        },
      };
    case "CLOSE_EDIT_COMMENT":
      return {
        ...state,
        editComment: {
          commentId: "",
          open: false,
        },
      };
    case "EDIT_SUB_COMMENT":
      return {
        ...state,
        editSubComment: {
          commentId: action?.data?.commentId,
          parentId: action?.data?.parentId,
          open: true,
        },
        reply: "",
        subReply: "",
        replyEmail: "",
        subCommentMenu: {
          commentId: "",
          open: false,
        },
      };
    case "CLOSE_EDIT_COMMENT":
      return {
        ...state,
        editSubComment: {
          commentId: "",
          parentId: "",
          open: false,
        },
      };
    case "HANDLE_SUB_REPLY":
      return {
        ...state,
        subReply: action?.data?.id,
        replyEmail: action?.data?.email,
        editSubComment: {
          commentId: "",
          parentId: "",
          open: false,
        },
      };
    case "HANDLE_EMBED_CLICKED":
      return {
        ...state,
        embedClicked: action?.value,
      };
    case "HANDLE_SELECTED_EMBED":
      return {
        ...state,
        selectedEmbed: action?.value,
      };
    case "HANDLE_EMBED_VIEW_NAME":
      return {
        ...state,
        captureEmbedValues: {
          ...state?.captureEmbedValues,
          viewName: action?.value,
        },
      };
    case "HANDLE_EMBED_VIEW_TYPE":
      return {
        ...state,
        captureEmbedValues: {
          ...state?.captureEmbedValues,
          viewType: action?.value,
        },
      };
    case "HANDLE_CREATOR_NAME_CAPTURE":
      return {
        ...state,
        captureEmbedValues: {
          ...state?.captureEmbedValues,
          creatorName: action?.value,
        },
      };
    case "HANDLE_CREATOR_NAME_VIDEO":
      return {
        ...state,
        videoEmbedValues: {
          ...state?.videoEmbedValues,
          creatorName: action?.value,
        },
      };
    case "HANDLE_ICON_COLOR_CAPTURE":
      return {
        ...state,
        captureEmbedValues: {
          ...state?.captureEmbedValues,
          colorValue: action?.value,
        },
      };
    case "HANDLE_ICON_COLOR_VIDEO":
      return {
        ...state,
        videoEmbedValues: {
          ...state?.videoEmbedValues,
          colorValue: action?.value,
        },
      };
    case "HANDLE_CUSTOM_DIMENSIONS_WIDTH":
      return {
        ...state,
        captureEmbedValues: {
          ...state?.captureEmbedValues,
          customizeDimensions: {
            ...state?.captureEmbedValues?.customizeDimensions,
            width: action?.value,
          },
        },
      };
    case "HANDLE_CUSTOM_DIMENSIONS_HEIGHT":
      return {
        ...state,
        captureEmbedValues: {
          ...state?.captureEmbedValues,
          customizeDimensions: {
            ...state?.captureEmbedValues?.customizeDimensions,
            height: action?.value,
          },
        },
      };
    case "RESET_DIMENISIONS":
      return {
        ...state,
        captureEmbedValues: {
          ...state?.captureEmbedValues,
          customizeDimensions: {
            width: "",
            height: "",
          },
        },
      };
    case "CHANGING_VIDEO_RADIUS":
      return {
        ...state,
        videoEmbedValues: {
          ...state?.videoEmbedValues,
          borderRadius: action?.value,
        },
      };
    case "CHANGING_VIDEO_DIMENSIONS":
      return {
        ...state,
        videoEmbedValues: {
          ...state?.videoEmbedValues,
          videoDimensions: {
            height: action?.height,
            width: action?.width,
          },
        },
      };
    case "TOGGLE_ANALYTICS_REPORT":
      return {
        ...state,
        analyticsClicked: action?.value,
        filterMenu: {
          open: false,
          value: "Last 30 days",
        },
      };
    case "TOGGLE_VIDEO_CTA":
      return {
        ...state,
        videoCtaClicked: action?.value,
      };
    case "CHANGE_CTA_FORM_VALUE":
      return {
        ...state,
        selectedForm: action?.value,
      };
    case "SAVE_SALES_FORM":
      return {
        ...state,
        salesForm: action.value,
      };
    case "HANDLE_FILTER_MENU":
      return {
        ...state,
        filterMenu: { ...state?.filterMenu, open: action?.data },
      };
    case "HANDLE_FILTER_VALUE":
      return {
        ...state,
        filterMenu: { ...state?.filterMenu, value: action?.data },
      };
    case "HANDLE_SALESFORM_SETTINGS":
      return {
        ...state,
        salesFormSettings: {
          showQuestionMenu: action?.value,
          questionIndex: action?.index,
        },
      };
    case "HANDLE_SHARE_POPUP":
      return {
        ...state,
        sharePopup: {
          open: action?.data,
          sharedEmails: [],
        },
      };
    case "HANDLE_SHARED_EMAILS":
      return {
        ...state,
        sharedEmails: action?.data,
      };
    case "HANDLE_INVITE":
      return {
        ...state,
        invite: { open: action?.data },
      };
    case "CHANGE_SHARE_TYPE":
      return {
        ...state,
        shareType: action?.data,
      };
    case "OPEN_OWNER_CHANGE":
      return {
        ...state,
        ownerChange: {
          open: true,
        },
      };

    case "CLOSE_OWNER_CHANGE":
      return {
        ...state,
        ownerChange: {
          open: false,
        },
      };
    case "FETCHING_CAPTURE_DEMO_STEPS":
      return {
        ...state,
        email: action?.data?.email,
        demoDocumentDetails: action?.data?.details,
        demoDocumentData: action?.data?.demoSteps,
        shareType: action?.data?.shareType,
        getDemoDocs: action?.data?.getDemoDocs,
        demoBrandKitDetails: action?.data?.brandKitDetails
      };
    case "CHAGING_THE_DEMO_STEP":
      return {
        ...state,
        htmlDemoActiveStep: action?.value,
      };
    case "HANDLE_ISSUE_BOX":
      return {
        ...state,
        issueBox: { open: action?.data },
      };
    case "HANDLE_TITLE_POPUP":
      return {
        ...state,
        showTitlePopup: action?.data,
      };
    case "CANCEL_DELETE_DEMO_POPUP":
      return {
        ...state,
        demoDeletePopup: action?.data,
      };
    case "DEMO_CHOOSEN_HANDLE":
      return {
        ...state,
        demoStepMenu: {
          open: action?.data?.open,
          id: action?.data?.id,
          previousStepNumber: action?.data?.previousStepNumber,
          nextStepNumber: action?.data?.nextStepNumber
        },
      };
    case "HANDLE_FEATURE_SELECTED":
      return {
        ...state,
        featureSeleceted: action?.data,
      };
    case "HANDLE_FONT_MENU":
      return {
        ...state,
        fontMenu: { open: action?.data?.open },
      };
    case "HANDLE_FONT_SELECTED":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          font: action?.data

        },
        fontMenu: {
          open: false
        },

      };
    case "HANDLE_FONT_SELECTED_CAPTURE":
      return {
        ...state,
        captureEditCallout: {
          ...state?.captureEditCallout,
          font: action?.data

        },
        fontMenu: {
          open: false
        },

      };
    case "HANDLE_ALLIGN_MENU":
      return {
        ...state,
        allignmentMenu: { open: action?.data?.open },
      };
    case "HANDLE_ALLIGN_SELECTED":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          arrowAlignment: action?.data
        },
        allignmentMenu: { open: false },

      };
    case "HANDLE_ALLIGN_SELECTED_CAPTURE":
      return {
        ...state,
        captureEditCallout: {
          ...state?.captureEditCallout,
          arrowAlignment: action?.data
        },
        allignmentMenu: { open: false },

      };
    case "HANDLE_BDRCOLOR":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          borderColor: action?.data,
        },
      };
    case "HANDLE_BDRCOLOR_CAPTURE":
      return {
        ...state,
        captureEditCallout: {
          ...state?.captureEditCallout,
          borderColor: action?.data,
        },
      };
    case "HANDLE_BGCOLOR":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          backgroundColor: action?.data,
        },
      };
    case "HANDLE_BGCOLOR_CAPTURE":
      return {
        ...state,
        captureEditCallout: {
          ...state?.captureEditCallout,
          backgroundColor: action?.data,
        },
      };

    case "HANDLE_BTNCOLOR":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          buttonColor: action?.data,
        },
      };
    case "HANDLE_BTNCOLOR_CAPTURE":
      return {
        ...state,
        captureEditCallout: {
          ...state?.captureEditCallout,
          buttonColor: action?.data,
        },
      };
    case "HANDLE_COLOR":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          textColor: action?.data,
        },
      };
    case "HANDLE_COLOR_CAPTURE":
      return {
        ...state,
        captureEditCallout: {
          ...state?.captureEditCallout,
          textColor: action?.data,
        },
      };
    case "HANDLE_HEAD_COLOR":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          headingColor: action?.data,
        },
      };
    case "HANDLE_HEADING":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          heading: action?.data,
        },
      };
    case "HANDLE_HEADING_CAPTURE":
      return {
        ...state,
        captureEditCallout: {
          ...state?.captureEditCallout,
          heading: action?.data,
        },
      };
    case "HANDLE_TEXT":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          text: action?.data,
        },
      };
    case "HANDLE_TEXT_CAPTURE":
      return {
        ...state,
        captureEditCallout: {
          ...state?.captureEditCallout,
          text: action?.data,
        },
      };
    case "HANDLE_TEXT_STYLE":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          textBold: action?.data?.textBold,
          textItalic: action?.data?.textItalic,
          textUnderline: action?.data?.textUnderline
        },
      };

    case "HANDLE_CONFIGURATION":
      return {
        ...state,
        demoEditConfig: {
          ...state?.demoEditConfig,
          prevArrow: action?.data?.prevArrow,
          nextArrow: action?.data?.nextArrow,
          stepNo: action?.data?.stepNo,
          textBtn: action?.data?.textBtn,
          iconBtn: action?.data?.iconBtn,
        },
      };
    case "HANDLE_CONFIGURATION_CAPTURE":
      return {
        ...state,
        captureEditConfig: {
          ...state?.captureEditConfig,
          prevArrow: action?.data?.prevArrow,
          nextArrow: action?.data?.nextArrow,
          stepNo: action?.data?.stepNo,
          textBtn: action?.data?.textBtn,
          iconBtn: action?.data?.iconBtn,
        },
      };
    case "HANDLE_ACTIVE_EDIT_STEP":
      return {
        ...state,
        demoEditConfig: action?.data?.config,
        demoEditcallOut: action?.data?.callout,
        activeStepId: action?.data?.active,
        activeStepIndex: action?.data?.index,
        activeStepCord: action?.data?.activeStepCord,
        featureSeleceted: '',
        lastStepText: action?.data?.callout?.text,
        lastStepHeading: action?.data?.callout?.heading
      };
    case "HANDLE_CALLOUT_STYLE":
      return {
        ...state,
        demoEditcallOut: {
          ...state?.demoEditcallOut,
          calloutStyle: action?.data
        }
      };
    case "HANDLE_CALLOUT_STYLE_CAPTURE":
      return {
        ...state,
        captureEditCallout: {
          ...state?.captureEditCallout,
          calloutStyle: action?.data
        }
      };
    case "HANDLE_THEME_COLOR":
      return {
        ...state,
        demoDocumentDetails: {
          ...state?.demoDocumentDetails,
          backgroundcolor: action?.data

        }
      };
    case "HANDLE_THEME_COLOR_CAPTURE":
      return {
        ...state,
        documentDetails: {
          ...state?.documentDetails,
          backgroundcolor: action?.data

        }
      };
    case "HANDLE_THEME_OPEN":
      return {
        ...state,
        themeOpened: action?.data
      };
    case "HANDLE_COLOR_OPEN":
      return {
        ...state,
        colorOpenMenu: action?.data
      };
    case "HANDLE_MENU_POSITION":
      return {
        ...state,
        activeMenuPosition: action?.data,
        activeMenuHorPositon: action?.value

      };
    case "HANDLE_START_TEXT":
      return {
        ...state,
        demoDocumentDetails: {
          ...state?.demoDocumentDetails,
          'startPageText': action?.data
        }
      };
    case "HANDLE_LAST_TEXT":
      return {
        ...state,
        lastStepText: action?.data
      };
    case "HANDLE_LAST_HEADING":
      return {
        ...state,
        lastStepHeading: action?.data
      };
    case "HANDLE_CAPTURE_EDIT_STEP":
      return {
        ...state,
        captureEditConfig: action?.data?.config,
        captureEditCallout: action?.data?.callout,
        captureActiveStepId: action?.data?.active,
        captureActiveStepIndex: action?.data?.index,
        captureActiveStepCord: action?.data?.activeStepCord,
        featureSeleceted: '',
      }
    case "HANDLE_INTRO_OUTRO_URL":
      return {
        ...state,
        captureVideoUrls: action?.data
      }
    case "HANDLE_INTRO_OUTRO_URL_DEMO":
      return {
        ...state,
        demoVideoUrls: action?.data
      }
    case "HANDLE_INTRO_OUTRO_MENU":
      return {
        ...state,
        introOutroMenu: {
          open: action?.data?.open,
          id: action?.data?.id,
        },
      };
    case "HANDLE_INTRO_OUTRO_POPUP":
      return {
        ...state,
        introOutroPopup: {
          open: action?.data?.open,
          id: action?.data?.id,
        },
      };
      case "HANDLE_SELECTED_INTRO_OUTRO":
      return {
        ...state,
        selectedIntroOutro: {
          introVideo: action?.data?.introVideo,
          outroVideo: action?.data?.outroVideo,
        },
      };

  }
};
const useCpatureReducer = () => {
  return useReducer(CpatureReducer, initialState);
};

export const CaptureProvider = ({ children }) => {
  const [state, dispatch] = useCpatureReducer();
  const movingDivRef = useRef(null);
  const editImageRef = useRef(null);
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const headingRef = useRef(null)
  const descRef = useRef(null)
  const saveBtnRef = useRef(null)
  const auth = useAuth()
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const introFileRef = useRef(null);
  const outroFileRef = useRef(null)
  const uploadIntroOutroVideo = async (
    files,
    fileName,
    type, url
  ) => {

    return new Promise(async (resolve, reject) => {
      try {
        const uploadResponse = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': files.type,
          },
          body: files,
        });
        if (uploadResponse?.ok) {
          resolve(uploadResponse?.url.split("?")[0])
        } else {
          reject(null)
        }

      } catch (e) {
        reject(null)
      }
    })

  };
  const undo = () => {
    if (currentIndex > 1) {
      dispatch({ type: "UNDO", data: history[currentIndex - 1] });
      setCurrentIndex(currentIndex - 1);
    }
  };

  const redo = () => {
    if (currentIndex < history.length - 1) {
      dispatch({ type: "REDO", data: history[currentIndex + 1] });
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <CpatureContext.Provider
      value={{
        captureState: state,
        captureDispatch: dispatch,
        movingDivRef,
        undo,
        redo,
        setHistory,
        setCurrentIndex,
        currentIndex,
        history,
        editImageRef,
        headingRef,
        descRef,
        saveBtnRef,
        uploaded,
        uploadIntroOutroVideo,
        introFileRef,
        outroFileRef
      }}
    >
      {children}
    </CpatureContext.Provider>
  );
};

export const useCapture = () => {
  return useContext(CpatureContext);
};
