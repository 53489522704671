import React, { useEffect, useState } from "react";
import { PresentRecordWrap, PresentationWrap } from "./presentation-styled";
import UploadPptCompt from "./component/uploadPptCompt";
import SideSlides from "./component/sideSlides";
import SlideBoady from "./component/slideBoady";
import PresentationMianSection from "./component/presentationMianSection";
import { DashboardProvider } from "../../context/dashboard/dashAuth";

const Presentation = () => {
  const [pres, setPres] = useState(false);
  const [imgSrc,setImgSrc] =useState();
  const [imgUrls,setImg]=useState()
  const [fileName,setFileName]=useState("")
  useEffect(()=>{
    if(imgUrls?.length>0){
      setImgSrc(imgUrls[0]?.url);
      setPres(true)
    }
  },[imgUrls])
  return (
    <>
      {(!pres&& !imgUrls)? (
        <PresentationWrap>
          <UploadPptCompt setImg={setImg} setPres={setPres} setFileName={setFileName}/>
        </PresentationWrap>
      ) : (
        <DashboardProvider>
        <PresentationMianSection pdfUrl={imgUrls} fileName={fileName}/>
        </DashboardProvider>
       
      )}
    </>
  );
};

export default Presentation;
