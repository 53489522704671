import mixpanel from "mixpanel-browser";
import { createContext, useContext, useReducer, useState } from "react";
import _static from "../../config/static";
import { Uploader } from "../../utils/uploadFn";
import { useAuth } from "../auth";
import { useParams } from "react-router-dom";

const DashboardContext = createContext(null);
const initialState = {
  personalized_notificationsIds:[],
  personalized_notifications:[],
  general_notificationsIds:[],
  general_notifications:[]
 
};
const DashboardReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_NOTIFICATION":
      return {
        ...state,
        personalized_notificationsIds: action?.data?.personalized_notificationsIds,
        personalized_notifications:action?.data?.personalized_notifications,
        general_notificationsIds:action?.data?.general_notificationsIds,
        general_notifications:action?.data?.general_notifications
      };
  }
};

const useDashboardReducer = () => {
  return useReducer(DashboardReducer, initialState);
};
export const DashboardProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [state,dispatch]=useDashboardReducer();
  const [summeryDone,setSummeryDone]=useState(false);
  const [searchText,setSearchText] = useState("")
  const [transcriptText,setTranscriptText]=useState([]);
  const [transcriptStart,setTranscriptStart]=useState(false);
  const [chaptersStart,setChaptersStart] =useState('')
   const [startSummary,setStartsummary]=useState(false)
  const { aws_Url } = _static;
  const [uploaded,setUploaded]=useState(false);
  const auth = useAuth();
  const params = useParams();
  const userName = params?.username
  ? params.username
  : auth?.authState?.user?.email?.split("@")[0];
  const uploadLocalVideo = async (
    files,
    fileName,
    totalDuration,
    durBlob,
    name,
    recordType
  ) => {
    const videoUploaderOptions = {
      fileName: fileName,
      file: files,
      username: auth?.authState?.user?.email?.split("@")[0],
      duration: totalDuration,
      contentType: files?.type,
      durBlobb: durBlob,
      linkUrl: `https://video-src-recorded.s3.ap-south-1.amazonaws.com/${fileName}`,
      name,
      recordType
    };
  
    let percentage = undefined;
    const uploader = new Uploader(videoUploaderOptions);
    setUploadDone(true);
    auth?.setActiveNoti("Upload");
  
    return new Promise((resolve, reject) => {
      uploader
        .onProgress(({ percentage: newPercentage }) => {
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            setUploadProgress(newPercentage);
          }
        })
        .onError((error) => {
          console.error(error);
          setUploadDone(false);
          auth?.setNotify(true);
          auth?.setNotificationText("Something went wrong");
          setUploadProgress(0);
          setTimeout(() => auth?.setNotify(false), 2000);
          reject(error);
        })
        .onComplete(async (data) => {
          if (data?.status === 200 && data.data.msg === "Uploaded Successfully") {
            setUploadDone(false);
            setUploaded(true);
            auth?.setActiveNoti("");
            setUploadProgress(0);
            auth?.setNotify(true);
            auth?.setNotificationText("Video Uploaded Successfully");
            mixpanel.track("Video Uploaded Locally", {
              name: auth?.authState?.user?.username,
              email: auth?.authState?.user?.email,
              count: auth?.authState?.user?.videoCount,
            });
            setTimeout(() => auth?.setNotify(false), 2000);
            resolve(data.data); // Propagate the result
          } else {
            reject(new Error("Upload failed"));
          }
        });
    });
  };
  
 const gettransCribe=async(fileName,parentId,id,mode)=>{
  if(mode==='transcript'){
    setTranscriptStart(true)
  }else{
    setChaptersStart('progress');
  }
    await fetch(
      `${aws_Url}/revamp/transcript`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          inputPath: !id?userName + "/":userName+"/trim" + "/",
          filename: fileName,
          id: parentId,
        }),
      }
    ).then(response => {
      const reader = response.body.getReader();

      function read() {
      reader.read().then(({ done, value }) => {
      if (done) {
        transcribeVideoLink(fileName,id,mode);
        if(mode==='chapters'){
          setChaptersStart('done')
        }
        mixpanel.track("Transcribe Created", {
          name: auth.authState.user.username,
          email: auth.authState.user.email,
          count:auth.authState.user.videoCount
        });
      return;
      }
      const chunk = new TextDecoder('utf-8').decode(value);
      if(chunk==="error\n"){
        return;
      }else{
      }
      
      read();
      });
      }
      read();
      })
      .catch(error => console.error('Error:', error));
  

}
const transcribeVideoLink = async (fileName,id,mode) => {
  let url;
  if(id){
    url=  `https://dfw25j7e2vqby.cloudfront.net/${userName}/trim/transcribe/${fileName}.vtt`
  }else{
    url = `https://dfw25j7e2vqby.cloudfront.net/${userName}/transcribe/${fileName}.vtt`
  }
  if(mode==='transcript'){
    setTranscriptStart(true)

  }
  await fetch(
   url
 ).then((res) => res.text())
       .then((data) => {
        if(mode==='transcript'){
        setTranscriptStart(false)
        }
         setTranscriptText(data?.replace("WEBVTT", "")?.split("\n\n"));
       });
   }
   const generateSummary=async(parentId,id)=>{
    setStartsummary(true)
    await fetch(
      `${aws_Url}/revamp/generateSummary`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          inputPath: !id?userName + "/":userName+"/trim" + "/",
          filename: parentId,
          id: parentId,
        }),
      }
    ).then(response => {
      const reader = response.body.getReader();
      function read() {
      reader.read().then(({ done, value }) => {
      if (done) {
        setSummeryDone(true);
        mixpanel.track("Summary Created", {
          name: auth.authState.user.username,
          email: auth.authState.user.email,
          count:auth.authState.user.videoCount
        });
      return;
      }
      const chunk = new TextDecoder('utf-8').decode(value);
      if(chunk==="error\n"){
        setSummeryDone(true);
         setStartsummary(false)
        return;
      }else{
      }
      
      read();
      });
      }
      read();
      })
      .catch(error => {console.error('Error:', error);setStartsummary(true);setSummeryDone(true);});
  

}
  return (
    <DashboardContext.Provider
      value={{
        setData,
        data,
        uploadLocalVideo,
        uploadDone,
        setUploadDone,
        uploadProgress,
        setUploadProgress,
        gettransCribe,
        dashState:state,
        dashDispatch:dispatch,
        transcriptText,
        transcribeVideoLink,
        setTranscriptText,
        uploaded,
        setUploaded,
        generateSummary,
        setSummeryDone,
        summeryDone,
        setStartsummary,
        startSummary,
        setTranscriptStart,
        transcriptStart,
        searchText,
        setSearchText,
        chaptersStart,
        setChaptersStart
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashAuth = () => {
  return useContext(DashboardContext);
};
