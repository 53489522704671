import styled, { keyframes } from "styled-components";
import Default from "../Dashboard/BrandKit/Images/defaultBg.svg";
import theme from "../../theme";

export const PresentationWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const UploadPptComptWrap = styled.div`
  width: 52.8%;
  border-radius: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.04);
  height: 469px;
  padding: 4% 9%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  .close-img {
    position: absolute;
    right: 4%;
  }
`;
export const UploadPptHeadWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #404b59;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
`;
export const FileDRagDropWrap = styled.div`
  border-radius: 10px;
  border: 0.5px dashed rgba(101, 40, 247, 0.25);
  background: #f7f8fa;
  width: 100%;
  height: 241px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  input {
    display: none;
  }
`;
export const DragText = styled.div`
  color: #667085;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
`;
const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

// Create a styled image component with the animation
export const Loadimg = styled.img`
  width: 40px; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
  animation: ${rotate} 1s linear infinite; /* Rotate every 2 seconds */
`;
export const Uploading = styled.div`
  margin-top: 5px;
  color: #858aab;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;
export const BrowseBtn = styled.button`
  border-radius: 5px;
  border: 1px solid #6528f7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 39px;
  color: #6528f7;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 28px;
`;
export const FormatTextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #858aab;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-top: 38px;
`;
export const PresentRecordWrap = styled.div`
  display: flex;
  justify-content: ${(props) => (props?.notSec ? "flex-start" : "center")};
  align-items: ${(props) => (props?.notSec ? "flex-start" : "center")};
  width: 100%;
  height: 100%;
  gap: 47px;
  position: relative;
`;
export const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  color: #404b59;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const SideSlidesWrap = styled.div`
  background: #fefefe;
  width: 12%;
  height: 100%;
  margin-top: 90px;
`;
export const SlidesHead = styled.div`
  padding: 18px 24px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const SlideImgWrap = styled.div`
  border-radius: 2px;
  background: rgba(237, 239, 242, 0.9);
  width: 28px;
  height: 28px;
  border-bottom: 1px solid #edeff2;
  cursor: pointer;
`;
export const SlidesFlowWrap = styled.div`
  padding: 28px 0px 28px 0px;
  border-right: 1px solid #edeff2;
  background: #fefefe;
  height: 100%;
  width: 100%;
  border-top: 1px solid #edeff2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;
export const FlowCont = styled.div`
  background-color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 85%;
  padding-left: 13px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 6px;
  }
`;
export const FlowImgWrap = styled.div`
  width: 90%;
  height: 130px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  color: #404b59;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: 0.06px;
  cursor: pointer;
  img {
    width: 90%;
    height: 110px;
    object-fit: contain;
  }
  &:hover {
    border-radius: 4px;
    background: #f6f2ff;
  }
`;

export const FlowControls = styled.div`
  height: 15%;
  width: 100%;
  background: #f7f8fa;
  padding: 20px 22px 0px 28px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const ControlsWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
`;
export const StepsWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
  color: #404b59;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.06px;
  width: 100%;
`;
export const ActiveStep = styled.div`
  border-radius: 4px;
  border: 0.4px solid rgba(0, 0, 0, 0.07);
  background: #fff;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`;
export const TotelStep = styled.div`
  border-radius: 4px;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`;

export const ArrowWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  background: rgba(237, 239, 242, 0.9);
  padding: 6px 10px;
  .right-arr {
    transform: rotate(180deg);
  }
  img {
    cursor: pointer;
  }
`;
export const CameraWrap = styled.div`
  display: flex;
  border-radius: 50%;
  position: absolute;
  background-color: red;
  z-index: 99999999;
  cursor: grab;
  user-select: none;
  video {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const SlideBoadyWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${(props)=>props?.hidepanel?'100%':'88%'};
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
`;
export const SlideNameWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  color: #404b59;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: 0.32px;
  background: #fff;
  width: 100%;
`;
export const SlideMainWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 2%;
  position: relative;
`;
export const SlidesMainCont = styled.div`
  width: 84%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  .can {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
export const StartRecBtnWrap = styled.div`
  display: flex;
  justify-content: ${(props) => (props?.isRecording ? "space-between" : "center")};
  align-items: center;
  width: 100%;
  padding-left: ${(props)=>props?.hidepanel?'60px':0};
`;
export const CounterWrap = styled.div`
  width: 80px;
  height: 32px;
  border-radius: 5px 5px 5px 5px;
  background: #b293fb;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: 0.07px;
  gap: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 40%;
  bottom:30px;
`;
export const CancelRecording = styled.div`
display: flex;
width: 180px;
height: 44px;
padding: 10px;
border-radius: 4px;
background: #E6E6E6;
justify-content: center;
align-items: center;
gap: 6px;
flex-shrink: 0;
color: #667085;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
letter-spacing: 0.07px;
margin-top: 28px;
cursor: pointer;
`
export const SlidesMain = styled.canvas`
  display: block;
  width: auto;
  height: auto;
  border-radius: 6px;
  /* background-image: url(${Default}); // Replace with your image path
  background-size: cover; // Optional: You can adjust this to control how the image covers the container
  background-repeat: no-repeat;
  img{
    width: 100%;
    height: 100%;
  } */
`;
export const StartRecBtn = styled.button`
  display: flex;
  width: 232px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #6528f7;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: 0.07px;
  border: none;
  margin-top: 28px;
  &.stop {
    border-radius: 4px;
    background: #ff5454;
  }
`;

export const RecButtonsWrap =styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 7px;
`

export const PauseResumeBtn = styled.div`
display: flex;
width: 180px;
height: 44px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 6px;
color: #667085;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
letter-spacing: 0.07px;
border-radius: 4px;
background: #E6E6E6;
margin-top: 28px;
cursor: pointer;
`

export const SlideModeSettingsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 100%;
`;
export const SlideModeSettingsCont = styled.div`
  border-radius: 30px;
  border: 0.5px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  width: 100%;
  padding: 5.7% 13%;
  .dividor {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
`;
export const SettingsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #667085;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.24px;
  position: relative;
  cursor: pointer;
  .text {
    margin-top: 5px;
    margin-bottom: 24px;
  }
  .sketch-picker {
    position: absolute;
    left: -240px;
    top: -130px;
    z-index: 99999999;
    width: 240px !important;

    height: 360px !important;
    .flexbox-fix > *:nth-child(n + 10) {
      display: none; /* Hide the 10th and subsequent children */
    }
  }
  &.mic-notavail{
    opacity: 0.5;
    pointer-events: none;
  }
`;
export const LayoutMenuWrap = styled.div`
  position: absolute;
  border-radius: 6px;
  background: #fff;
  width: 170px;
  height: 274px;
  flex-shrink: 0;
  top: 0px;
  left: -200px;
  .dividor {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 6px;
    margin-top: 8px;
  }
`;
export const LayoutItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 14px;
  img {
    margin-top: 8px;
  }
`;
export const LayoutItem = styled.div``;

export const LayoutMenuHeadWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #667085;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: 0.24px;
  padding: 11px 14px 0px 14px;
  img {
    width: 12px;
    height: 12px;
  }
`;
export const CancelBtn = styled.div`
  display: flex;
  height: 33px;
  padding: 0px 20px;

  justify-content: center;
  align-items: center;
  gap: 94px;
  border-radius: 5px;
  border: none;
  color: #5429ff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  background-color: none;
  cursor: pointer;
`;
export const ColorSaveBtn = styled.button`
  border: "none";
  cursor: pointer;
  border-radius: 4px;
  background-color: #6528f7;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.07px;
  z-index: 99999999;
  height: 33px;
  padding: 0px 20px;
`;
export const SaveCancelCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2px;
  position: absolute;
  bottom: 15px;
  position: absolute;
  top: ${(props) => (props?.design ? "-85px" : "210px")};
  left: -20%;
  transform: ${(props) =>
    props?.design ? "translateX(0%)" : "translateX(-90%)"};
  z-index: 99999999;
`;

export const PropmptBoxWrap = styled.div`
  width: 400px;
  height: 260px;
  flex-shrink: 0;
  border-radius: 6px;
  background: rgba(44, 43, 43, 0.84);
  backdrop-filter: blur(1px);
  position: absolute;
  right: 30px;
  bottom: 100px;
  .wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const PromptBoxHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.24px;
  height: 10%;
  img {
    cursor: pointer;
  }
`;
export const PromptTextArea = styled.textarea`
  color: rgba(255, 255, 255, 0.7);
  font-family: "Inter";
  font-size: ${(props) => props?.fontSize + "px"};
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 24px */
  letter-spacing: 0.3px;
  width: 100%;
  height: 88%;
  overflow-y: auto;
  resize: none;
  margin-top: 6px;
  padding: 6px 20px;
  background: rgba(44, 43, 43, 0.84);
  border: none;
  outline: none;
  position: relative;
`;
export const PropmptFoot = styled.div`
  display: flex;
  justify-content: flex-end;
  border-radius: 0px 0px 6px 6px;
  background: rgba(57, 57, 57, 0.4);
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 20px;
`;
export const FontWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
  cursor: pointer;
  .increase {
    background-color: #595959;
    width: 50px;
    height: 24px;
    border-radius: 10px 0 0 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: black;
    }
  }
  .decrease {
    background-color: #595959;
    width: 50px;
    height: 24px;
    border-radius: 0 10px 10px 0px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: black;
    }
  }
`;
export const VidedoPreviewWrap = styled.div`
  display: flex;
  top: 0%;
  left: 0%;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 999;
  width: 100%;
  height: 100%;
`;

export const PreviewBox = styled.div`
  background-color: white;
  /* padding:1.5%; */
  padding: 32px 50px 32px 50px;
  border-radius: 10px;
  font-size: 18px;
  /* width:30%; */
  ${theme.breakpoints.tab} {
    width: 65%;
  }
  ${theme.breakpoints.smallView} {
    width: 70%;
  }
  height: ${(props)=>!props?.linkUrl&&'695px'};
  width: 60.7%;
  margin-top: 1.8%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
`;

export const PreviewBoxHead = styled.div`

display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
color: #404B59;
font-family: 'Inter';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
letter-spacing: 0.32px;
img{
  cursor: pointer;
}
`
export const PlayerWrap = styled.div`
display: flex;
width: 100%;
justify-content: flex-start;
align-items: flex-start;
margin-top: 20px;
`
export const ButtonsWrap = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
width: 100%;
margin-top: 12px;
gap: 10px;

`

export const TrimBtn = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 78px;
height: 41px;
color: #667085;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
letter-spacing: 0.07px;
gap: 5px;
background-color: #F0F0F0;
border-radius: 5px;
cursor: pointer;

`
export const DownloadBtn =styled.div`
width: 108px;
height: 41px;
border-radius: 4px;
background: #6528F7;
color: #FFF;
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
letter-spacing: 0.07px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
`

export const LoadVideo = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
`