import { captureClient, dashClient } from "../../../../utils/axios-utils";
import smileEmoji from "../../../../assets/img/smile.png";
import likeEmoji from "../../../../assets/img/like.png";
import heartEmoji from "../../../../assets/img/heart.png";
import angryEmoji from "../../../../assets/img/angry.png";
import surpriseEmoji from "../../../../assets/img/surprise.png";
import cryEmoji from "../../../../assets/img/cry.png";

export const getVideoTimeReactions = (videoId, videoLocation) => {
    if (!videoId) return;
  
    if (!videoLocation.pathname.includes("capture")) {
      const config = {
        url: `/flonnect/api/timeline/gettimelinereactions/${videoId}`,
        method: "GET",
      };
      return dashClient(config);
    } else {
      const config = {
        url: `/api/capture/timeline/gettimelinereactions/${videoId}`,
        method: "GET",
      };
      return captureClient(config);
    }
  };
  
 export  const addTimeLineReactions = ({ newReactionData, videoLocation }) => {
    if (!videoLocation.pathname.includes("capture")) {
      const config = {
        url: "/flonnect/api/timeline/addtimelinereactions",
        method: "POST",
        data: newReactionData,
      };
  
      return dashClient(config);
    } else {
      const config = {
        url: "/api/capture/timeline/addtimelinereactions",
        method: "POST",
        data: newReactionData,
      };
  
      return captureClient(config);
    }
  };
  
 export  const deletTimeLineReaction = ({ interactionId, videoLocation }) => {
    if (!videoLocation.pathname.includes("capture")) {
      const config = {
        url: `/flonnect/api/timeline/deleteinteraction/${interactionId}`,
        method: "GET",
      };
      return dashClient(config);
    } else {
      const config = {
        url: `/api/capture/timeline/deleteinteraction/${interactionId}`,
        method: "GET",
      };
      return captureClient(config);
    }
  };

export const commonlyUsedeEmojis = [
    { name: "love", emojiUrl: heartEmoji, emoji: " ❤️" },
    { name: "smile", emojiUrl: smileEmoji, emoji: " 😂" },
    { name: "surprise", emojiUrl: surpriseEmoji, emoji: "😮" },
    { name: "sad", emojiUrl: cryEmoji, emoji: "😢" },
    { name: "angry", emojiUrl: angryEmoji, emoji: "😡" },
    { name: "like", emojiUrl: likeEmoji, emoji: "👍" },
  ];


  export const convertTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return hours > 0
      ? `${hours}:${formattedMinutes}:${formattedSeconds}`
      : `${minutes}:${formattedSeconds}`;
  };

export const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  export const fetchCaptions = async (subtitleUrl) => {
    if (!subtitleUrl) return null;
    
    const response = await fetch(subtitleUrl, {
      credentials: 'include',
      headers: {
        'Content-Type': 'text/vtt',
      },
    });
  
    if (!response.ok) {
      throw new Error(`Failed to fetch captions: ${response.status}`);
    }
  
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };