import React, { useState } from "react";
import {
  FontWrap,
  PromptBoxHead,
  PromptTextArea,
  PropmptBoxWrap,
  PropmptFoot,
} from "../presentation-styled";
import Close from "../Images/whiteClose.svg";

const PromptBox = ({
  setPrompt,
  selectedPage,
  propmptWithSilde,
  setPromptwithSlide,
}) => {
  const [fontSize, setFontSize] = useState(15);

  // Adjust font size
  const adjustFontSize = (increment) => {
    setFontSize((prev) => Math.max(prev + increment, 6)); // Minimum font size is 6
  };

  // Update text for the selected page
  const handleTextChange = (e) => {
    const updatedText = e.target.value;
    setPromptwithSlide((prev) =>
      prev.map((item) =>
        item.id === selectedPage ? { ...item, text: updatedText } : item
      )
    );
  };

  // Get text for the selected page
  const currentText =
    propmptWithSilde.find((item) => item.id === selectedPage)?.text || "";

  return (
    <PropmptBoxWrap
    // Prevent click propagation
    >
      <div className="wrap">
        <PromptBoxHead>
          <div>Speaker Notes</div>
          <img
            src={Close}
            alt="Close"
            draggable="false"
            onClick={(e) => {
              e.stopPropagation();
              setPrompt(false); // Close the prompt
            }}
          />
        </PromptBoxHead>
        <PromptTextArea
          type="text"
          placeholder="Type or Paste your text here..."
          style={{ fontSize: `${fontSize}px` }}
          value={currentText}
          onChange={handleTextChange}
        />
        <PropmptFoot>
          <FontWrap>
            <div className="increase" onClick={() => adjustFontSize(1)}>
              A+
            </div>
            <div className="decrease" onClick={() => adjustFontSize(-1)}>
              A-
            </div>
          </FontWrap>
        </PropmptFoot>
      </div>
    </PropmptBoxWrap>
  );
};

export default PromptBox;
