import React, { useEffect, useState } from "react";
import {
  ActiveStep,
  ArrowWrap,
  ControlsWrap,
  FlowCont,
  FlowControls,
  FlowImgWrap,
  SideSlidesWrap,
  SlidesFlowWrap,
  StepsWrap,
  TotelStep,
} from "../presentation-styled";
import leftArr from "../Images/leftArr.svg";
const SideSlides = ({ pdfDocument, selectedPage, setSelectedPage }) => {
  const [thumbnails, setThumbnails] = useState([]);
  useEffect(() => {
    const renderThumbnails = async () => {
      const thumbnailList = [];
      for (
        let pageNumber = 1;
        pageNumber <= pdfDocument.numPages;
        pageNumber++
      ) {
        const page = await pdfDocument.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 0.5 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport,
        };

        await page.render(renderContext).promise;

        thumbnailList.push({ pageNumber, src: canvas.toDataURL() });
      }
      setThumbnails(thumbnailList);
    };

    renderThumbnails();
  }, [pdfDocument]);
  return (
    <SideSlidesWrap>

      <SlidesFlowWrap>
        <FlowCont>
          {thumbnails.length > 0 ? (
            thumbnails.map(({ pageNumber, src }) => (
              <FlowImgWrap
                key={pageNumber}
                onClick={() => setSelectedPage(pageNumber)}

              >
                <div>{pageNumber}</div>
                <img
                  src={src}
                  alt={`Page ${pageNumber}`}
                  style={{
                    border:
                      selectedPage === pageNumber ? "1px solid blue" : "none",
                  }}
                />
              </FlowImgWrap>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </FlowCont>
        <FlowControls>
          <ControlsWrap>
            <StepsWrap>
              <ActiveStep>{selectedPage}</ActiveStep>
              <div>of</div>
              <TotelStep>{pdfDocument?.numPages}</TotelStep>
            </StepsWrap>
            <ArrowWrap>
              <img src={leftArr} onClick={() => {
                if (selectedPage > 1) {
                  setSelectedPage(selectedPage - 1)
                }
              }} />
              <img src={leftArr} className="right-arr" onClick={() => {
                if (selectedPage < pdfDocument?.numPages) {
                  setSelectedPage(selectedPage + 1)
                }
              }} />
            </ArrowWrap>
          </ControlsWrap>
        </FlowControls>
      </SlidesFlowWrap>
    </SideSlidesWrap>
  );
};

export default SideSlides;
