import React, {useRef, useEffect, useState, memo} from "react";
import { ProgressContainer, Progress, BufferProgress, Tooltip, ProgressThumb,ChapterPointsWrap, PointsItem, ProgressSegment, BufferProgressSegment} from "./CustomProgressBar.styled";
import { timeToSeconds } from "../../../../../utils/utils";

const isMemoize = (prev, next) => {
    if (prev?.data?.videoData?.id !== next?.data?.videoData?.id) return false;
    return true;
  };

const CustomProgressBar = ({ player,chapters=[] }) => {
    const progressRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const [progress, setProgress] = useState(0);
    const [buffered, setBuffered] = useState(0);
    const [tooltipTime, setTooltipTime] = useState('0:00');
    const [tooltipPosition, setTooltipPosition] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [isHovered, setIsHovered] = useState(false)

    const prepareChapterSegments = () => {
      if (!chapters || chapters.length === 0 || !duration) return [];
  
      return chapters.map((chapter, index) => {
        const startTime = timeToSeconds(chapter.time);
        const nextChapter = chapters[index + 1];
        const endTime = nextChapter 
          ? timeToSeconds(nextChapter.time) 
          : duration;
  
        return {
          ...chapter,
          startPosition: (startTime / duration) * 100,
          width: ((endTime - startTime) / duration) * 100
        };
      });
    };
  
    const chapterSegments = prepareChapterSegments();
  

    const customProgressBarReady = ()=>{
        if (!player?.duration) return;
        if(duration===0){
         setDuration(player?.duration)
        }

      const playerProgressContainer = player.elements?.controls?.querySelector(
        ".plyr__progress"
      );
      const existingCustomProgressBar = player.elements?.controls?.querySelector(
        "#custom-progress"
      );
        // const progressBarcontainer = player.elements?.progress;
        if(playerProgressContainer && !existingCustomProgressBar){
        playerProgressContainer?.prepend(progressRef?.current)
        }
    
        // Get duration when metadata is loaded
        const handleLoadedMetadata = () => {
          setDuration(player?.duration);
        };
    
        // Update progress on time update
        const handleTimeUpdate = () => {
          if (!isDragging) {
            const currentProgress = (player.currentTime / player.duration) * 100;
            setProgress(currentProgress);
          }
        };
    
        // Update buffer progress
        const handleProgress = () => {
          // if (player.buffered.length > 0) {
          //   const bufferedEnd = player.buffered.end(player.buffered.length - 1);
          //   const bufferedProgress = (bufferedEnd / player.duration) * 100;
          const bufferedProgress = player.buffered * 100;
  
            setBuffered(bufferedProgress);
          // }
        };
    
        // Add event listeners
        // player?.on('loadedmetadata', handleLoadedMetadata);
        player?.on('timeupdate', handleTimeUpdate);
        player?.on('progress', handleProgress);
    
        // Clean up
        return () => {
        //   player?.off('loadedmetadata', handleLoadedMetadata);
          player?.off('timeupdate', handleTimeUpdate);
          player?.off('progress', handleProgress);
        };
    }
    useEffect(() => {
       setTimeout(customProgressBarReady, 1000)
    }, [player, isDragging]);
  
    // Format time (converts seconds to MM:SS format)
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };
  
    // Handle mouse move on progress bar
    const handleMouseMove = (e) => {
      if (!progressRef.current || !duration) return;
  
      const rect = progressRef.current.getBoundingClientRect();
      const position = (e.clientX - rect.left) / rect.width;
      const time = position * duration;
  
      setTooltipTime(formatTime(time));
      setTooltipPosition(position * 100);
    };
  
    // Handle click on progress bar
    const handleClick = (e) => {
    //   if (!progressRef.current || !player || !duration) return;
      const rect = progressRef.current.getBoundingClientRect();
      const position = (e.clientX - rect.left) / rect.width;
      const newProgress = position * 100;
      setProgress(newProgress)

      player.currentTime = position * duration;
    };
  
    // Handle mouse down (start dragging)
    const handleMouseDown = () => {
      setIsDragging(true);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleDrag);
    };
  
    // Handle mouse up (stop dragging)
    const handleMouseUp = (e) => {
        e.stopPropagation();
      setIsDragging(false);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleDrag);
    };
  
    // Handle dragging
    const handleDrag = (e) => {
      if (!progressRef.current || !player || !duration) return;
  
      const rect = progressRef.current.getBoundingClientRect();
      const position = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
      const newProgress = position * 100;
      
      setProgress(newProgress);
      player.currentTime = position * duration;
    };

    const renderProgressSegments = () => {
      // If no chapters, use default single segment approach
      if (!chapters || chapters.length === 0) {
        return (
          <>
            {/* <BufferProgressSegment style={{ width: `${buffered}%` }} />
            <ProgressSegment style={{ width: `${progress}%` }} /> */}
            <BufferProgress style={{ width: `${buffered}%` }} />
        <Progress    style={{ width: `${progress}%` }} />
          </>
        );
      }
  
      // Render chapter-based segments
      return (
        <ChapterPointsWrap>
          {chapterSegments.map((segment, index) => {
            // Calculate buffer and progress for this segment
            const segmentBufferWidth = Math.min(buffered , segment.startPosition + segment.width) - segment.startPosition;
            const segmentProgressWidth = Math.min(progress , segment.startPosition + segment.width) - segment.startPosition;
            const bufferWidth = (segmentBufferWidth/segment.width) * 100;
            const progressWidth = (segmentProgressWidth/segment.width) * 100;
  
            return (
              <React.Fragment key={index}>
                <PointsItem elementWidth={segment.width}>
                  {/* Buffer segment for this chapter */}
                  {segmentBufferWidth > 0 && (
                    <BufferProgressSegment 
                      elementWidth={Math.max(0, segmentBufferWidth)} 
                      style={{ 
                        width: `${Math.max(0, bufferWidth)}%`,
                        left: 0 
                      }} 
                    />
                  )}
                  
                  {/* Progress segment for this chapter */}
                  {segmentProgressWidth > 0 && (
                    <ProgressSegment 
                      elementWidth={Math.max(0, segmentProgressWidth)} 
                      style={{ 
                        width: `${Math.max(0, progressWidth)}%`,
                        left: 0 
                      }} 
                    />
                  )}
                </PointsItem>
              </React.Fragment>
            );
          })}
        </ChapterPointsWrap>
      );
    };
  
    return (
      <ProgressContainer id="custom-progress"
        ref={progressRef}
        onClick={handleClick} 
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isHovered={isHovered}
        chapters={chapters}
      >
        {/* <BufferProgress style={{ width: `${buffered}%` }} />
        <Progress    style={{ width: `${progress}%` }} /> */}

{renderProgressSegments()}
        {/* <ProgressThumb 
        style={{ left: `${progress}%` }}
        onMouseDown={(e) => {
          e.stopPropagation();
          handleMouseDown();
        }}/> */}
        {/* <Tooltip style={{ left: `${tooltipPosition}%` }}>
          {tooltipTime}
        </Tooltip> */}
      </ProgressContainer>
    );
  }

  export default CustomProgressBar